<template>
  <div class="input-search">
    <div class="row">
      <input
        ref="searchInput"
        class="field"
        :class="{ field_providers: props.isProviders }"
        type="text"
        :placeholder="props.placeholder"
        :value="props.value"
        @input="onInput"
      />
      <atomic-icon id="search" />

      <atomic-icon v-if="props.isProviders" id="search-clear" class="icon_clear" @click="clearSearch" />
    </div>
  </div>
</template>

<script setup lang="ts">
  const props = withDefaults(
    defineProps<{
      placeholder?: string;
      value: string;
      isProviders?: boolean;
      autoFocus?: boolean;
    }>(),
    {
      placeholder: '',
      isProviders: false,
    }
  );

  const emit = defineEmits(['update:value', 'input']);
  const onInput = (e: any): void => {
    emit('update:value', e.target.value);
    emit('input', e.target.value);
  };

  const clearSearch = (): void => {
    emit('update:value', '');
    emit('input', '');
  };

  const searchInput = ref();
  onMounted(() => {
    if (props.autoFocus && searchInput.value) searchInput.value.focus();
  });
</script>

<style src="~/assets/styles/components/form/input/search.scss" lang="scss" />
